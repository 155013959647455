const AppSettings = {
    appTitle: 'Nomadis',
    mapsalgerie: '34.442277, 3.492232',
	module : 'Nomadis', // Recensement, Nomadis
    decimal : 3,
    show_poids : true,
    zoomalgerie : 7,
     //keymaps :'AIzaSyAMZBIUbxC0z-je7wnaB0Jk5J3FSDT-Vzs',
        keymaps :'AIzaSyD4LczsNyGOhObVIx-mooEO5fhKVc45jIE',
         apiUrl: 'https://api.goutinel.clediss.com/api/v1/',

herewego : {
                app_id : 'VxpPmyKjSh1FcbqCq7d7',
                app_code : 'sjYx8madd1LAp_IP0Qo1GQ'
           }
        ,
        //using mapbox accesstocken
        mapbox : {
                access_token : 'pk.eyJ1IjoiY2xlZGlzc25vbWFkaXMiLCJhIjoiY2p0b2w1MHJqMDU2czQ1cG5tY3Bu$'
        }
,
        "lang": "fr_FR"
        //"lang": "ar_AR"
};
export default AppSettings;

